import Emitter from '../../core/Emitter'
import { getScrollTop as getScrollTopNative, getScroll as getScrollNative, enableScrolling as enableScrollingNative, disableScrolling as disableScrollingNative } from '../Viewport/scroll'
import throttle from 'lodash/throttle'
import Scrollbar from 'smooth-scrollbar'

import { default as scrollToElementNative } from '../Viewport/scrollToElement'
import { default as scrollToNative } from '../Viewport/scrollTo'

class Scroll extends Emitter {
    constructor(context = window) {
        super()

        this.context = context
        this.current = {
            x: 0,
            y: 0,
        }

        this.limit = {
            x: 0,
            y: 0,
        }

        this.velocity = {
            x: 0,
            y: 0,
            time: 0,
        }
        this.raf = null
        this.container = document.querySelector('.scroll-Container')

        this.windowDimensions = this.getWindowDimensions()

        this.handleResize = throttle(this.handleResize, 100)

        window.addEventListener('resize', this.handleResize)
        window.addEventListener('scroll', this.handleNativeScroll)

        this.scrollbar = null
        this.isVirtual = false

        this.staticLimit = {
            x: this.container.offsetWidth - window.innerWidth,
            y: this.container.offsetHeight - window.innerHeight,
        }
    }

    getWindowDimensions() {
        return {
            x: window.outerWidth || window.innerWidth,
            y: window.outerHeight || window.innerHeight,
        }
    }

    getScroll() {
        if (this.isVirtual) {
            return {
                x: this.scrollbar.scrollLeft,
                y: this.scrollbar.scrollTop,
                limit: this.scrollbar.limit,
            }
        } else {
            return getScrollNative()
        }
    }

    handleNativeScroll = () => {
        if (this.isVirtual) {
            return
        }

        this.current = getScrollNative()

        this.render()
    }

    handleResize = () => {
        const dims = this.getWindowDimensions()
        const { x, y } = this.windowDimensions

        // if (dims.x !== x || dims.y !== y) {
        //     this.windowDimensions = dims
        //     this.resize()
        // }

        this.resize()
    }

    handleVirtualScroll = (status) => {
        const now = Date.now()
        const delta = now - this.velocity.time

        if (!delta || delta > 1000) {
            this.velocity.x = 0
            this.velocity.y = 0
        } else {
            this.velocity.x = (status.offset.x - this.current.x) / delta
            this.velocity.y = (status.offset.y - this.current.y) / delta
        }

        this.velocity.time = now

        this.current = {
            x: status.offset.x,
            y: status.offset.y,
        }

        this.render()
    }

    render = () => {
        this.emit('scroll', {
            offset: { ...this.current },
            limit: { ...this.limit },
            velocity: { ...this.velocity },
        })
    }

    resize = () => {
        if (!this.isVirtual) {
            this.staticLimit = {
                x: this.container.offsetWidth - window.innerWidth,
                y: this.container.offsetHeight - window.innerHeight,
            }
        }
        this.limit = this.getLimit()
        this.emit('resize')
    }

    setPosition(x, y) {
        if (this.isVirtual) {
            this.scrollbar.setPosition(x, y)
        } else {
            document.documentElement.scrollLeft = x
            document.documentElement.scrollTop = y
        }
    }

    useNative() {
        this.isVirtual = false
        this.container.classList.remove('is-virtual')
    }

    useVirtual() {
        this.isVirtual = true
        this.container.classList.add('is-virtual')

        if (!this.scrollbar) {
            this.scrollbar = Scrollbar.init(this.container, {
                renderByPixels: false,
            })
            this.scrollbar.addListener(this.handleVirtualScroll)
        }
    }

    getLimit() {
        if (this.isVirtual && this.scrollbar) {
            return this.scrollbar.limit
        } else {
            return this.staticLimit
        }
    }
}

const scrollInstance = new Scroll()

export function scrollToElement(element, options = { offset: 80 }) {
    if (scrollInstance.isVirtual) {
        scrollInstance.scrollbar.scrollIntoView(element, {
            offsetTop: options.offset ? options.offset : 0,
            onlyScrollIfNeeded: options.loose,
            alignToTop: true,
        })
    } else {
        scrollToElementNative(element, options)
    }
}

export function scrollTo(offset) {
    if (scrollInstance.isVirtual) {
        scrollInstance.scrollbar.scrollTo(0, offset, 600)
    } else {
        scrollToNative(offset)
    }
}

export function getScroll() {
    if (scrollInstance.isVirtual) {
        return scrollInstance.scrollbar.offset
    } else {
        return getScrollNative()
    }
}

export function getScrollTop() {
    if (scrollInstance.isVirtual) {
        return scrollInstance.scrollbar.scrollTop
    } else {
        return getScrollTopNative()
    }
}

export function disableScrolling() {
    disableScrollingNative()
}

export function enableScrolling() {
    enableScrollingNative()
}

window.scrollInstance = scrollInstance

export default scrollInstance
