import Component from '../core/Component'
import EventBus from '../core/EventBus'

const STATES = {
    READY: 'is-ready',
    COMPLETE: 'is-complete',
}

export const HeroSectionEvents = {
    AnimationComplete: 'hero:animation:complete',
}

export default class HeroSection extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            g: null,
            text: null,
        }
    }

    prepare() {
        EventBus.on('page:ready', this.handlePageReady)
    }

    handlePageReady = () => {
        this.element.classList.add(STATES.READY)
        this.animate().then(() => {
            this.element.classList.add(STATES.COMPLETE)
            EventBus.emit(HeroSectionEvents.AnimationComplete)
        })
    }

    async animate() {
        const g = Component.getFromElement(this.ref.g)
        const text = Component.getFromElement(this.ref.text)

        await g.animateIn()

        setTimeout(() => text.reveal(), 200)
    }
}
